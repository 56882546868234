import * as React from "react";
import { Footer } from "../../../Footer/Footer";
import { NavigationHeader } from "../../../NavigationHeader/NavigationHeader";
import { SEO } from "../../../SEO/SEO";
import { useTranslation } from "react-i18next";
import coderOnePreview from "../../../Images/coder-one.jpg";
import { UserProfileContent } from "./UserProfileContent";
import { AuthenticatedFrame } from "../../../AuthenticatedFrame/AuthenticatedFrame";
import { WithoutAuth } from "../../../Auth/WithoutAuth";
import { IUserProfileResponse } from "../../../../hooks/api/useUserProfile";

interface IProps {
    readonly profile: IUserProfileResponse | null;
    readonly username: string;
    readonly country?: string;
}

const UserProfile: React.FC<React.PropsWithChildren<IProps>> = ({ profile, username }) => {
    const [t] = useTranslation();
    const description = t("coderOneIsAnOpenPlayground");
    const title = t("publicProfile.title", { username });
    const socialImage = `https://${process.env.GATSBY_HOST}${coderOnePreview}`;
    const Content = <UserProfileContent username={username} profile={profile} />;
    const Fallback = <AuthenticatedFrame title={title}>{Content}</AuthenticatedFrame>;
    return (
        <React.Fragment>
            <SEO title={title} description={description} socialImage={socialImage} />
            <WithoutAuth fallback={Fallback}>
                <NavigationHeader />
                {Content}
            </WithoutAuth>
            <Footer />
        </React.Fragment>
    );
};

export default UserProfile;
