import * as React from "react";
import { useTranslation } from "react-i18next";
import LinkIcon from "./link.svg";
import { DetailContainer } from "./UserProperties.styles";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";

interface IProps {
    readonly url?: string | null;
}

export const WebsiteProperty: React.FC<React.PropsWithChildren<IProps>> = ({ url }) => {
    const [t] = useTranslation();
    if (url === null || undefined || url?.length == 0) {
        return null;
    }
    return (
        <DetailContainer>
            <img src={LinkIcon} alt={t("websiteUrl")} />
            <TelemetryLink href={`https://${url}`} target="_blank">{url}</TelemetryLink>
        </DetailContainer>
    );
};
