import * as React from "react";
import { Text } from "@fluentui/react";
import { DetailContainer } from "./UserProperties.styles";
import CompanyIcon from "./company.svg";

interface IProps {
    readonly organization?: string | null;
}

export const OrganizationProperty: React.FC<React.PropsWithChildren<IProps>> = ({ organization }) => {
    if (organization === null || undefined || organization?.length == 0) {
        return null;
    }
    return (
        <DetailContainer>
            <img src={CompanyIcon} />
            <Text>{organization}</Text>
        </DetailContainer>
    );
};
