import * as React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@fluentui/react";
import { Container, Headline, InformationContainer, Root, PropertiesContainer, SocialsContainer } from "./UserProfileContent.styles";
import { GithubProperty } from "./UserProperties/GithubProperty";
import { LinkedinProperty } from "./UserProperties/LinkedinProperty";
import { DiscordProperty } from "./UserProperties/DiscordProperty";
import { TwitterProperty } from "./UserProperties/TwitterProperty";
import { WebsiteProperty } from "./UserProperties/WebsiteProperty";
import { OrganizationProperty } from "./UserProperties/OrganizationProperty";
import { LevelProperty } from "./UserProperties/LevelProperty";
import { IUserProfileResponse } from "../../../../hooks/api/useUserProfile";
import { getRelativeTime } from "../../../../utilities/getRelativeTime";

interface IProps {
    readonly username: string;
    readonly profile: IUserProfileResponse | null;
}

export const UserProfileContent: React.FC<React.PropsWithChildren<IProps>> = ({ username, profile }) => {
    const [t] = useTranslation();
    if (profile === null || profile === undefined) {
        return (
            <Root>
                <Container>
                    <Headline>{t("publicProfile.notAvailable")}</Headline>
                    <Text>{t("publicProfile.brokenPage")}</Text>
                </Container>
            </Root>
        );
    }
    const { modified, properties } = profile;
    const { github, linkedin, discord, twitter, website_url, organization, level } = properties;
    const modifiedRelative = getRelativeTime(t, modified);

    return (
        <Root>
            <Container>
                <Headline>{username}</Headline>
                <InformationContainer>
                    <LevelProperty level={level} />
                    <Text>{t("publicProfile.joined", { joinDate: modifiedRelative })}</Text>
                </InformationContainer>
                <SocialsContainer>
                    <GithubProperty username={github} />
                    <LinkedinProperty username={linkedin} />
                    <TwitterProperty username={twitter} />
                </SocialsContainer>
                <PropertiesContainer>
                    <DiscordProperty username={discord} />
                    <WebsiteProperty url={website_url} />
                    <OrganizationProperty organization={organization} />
                </PropertiesContainer>
            </Container>
        </Root>
    );
};
