import * as React from "react";
import { Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import DiscordIcon from "./discord.svg";
import { DetailContainer } from "./UserProperties.styles";

interface IProps {
    readonly username?: string | null;
}

export const DiscordProperty: React.FC<React.PropsWithChildren<IProps>> = ({ username }) => {
    const [t] = useTranslation();
    if (username === null || undefined || username?.length == 0) {
        return null;
    }
    return (
        <DetailContainer>
            <img src={DiscordIcon} alt={t("discord")} />
            <Text>{username}</Text>
        </DetailContainer>
    );
};
