import * as React from "react";
import { useTranslation } from "react-i18next";
import GithubIcon from "./github.svg";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";

interface IProps {
    readonly username?: string | null;
}

export const GithubProperty: React.FC<React.PropsWithChildren<IProps>> = ({ username }) => {
    const [t] = useTranslation();
    if (username === null || undefined || username?.length == 0) {
        return null;
    }
    return (
        <TelemetryLink href={`${t("profilePage.githubPrefix")}${username}`}>
            <img src={GithubIcon} alt={t("github")} width="24px" />
        </TelemetryLink>
    );
};
