import * as React from "react";
import { useTranslation } from "react-i18next";
import LinkedinIcon from "./linkedin.svg";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";

interface IProps {
    readonly username?: string | null;
}

export const LinkedinProperty: React.FC<React.PropsWithChildren<IProps>> = ({ username }) => {
    const [t] = useTranslation();
    if (username === null || undefined || username?.length == 0) {
        return null;
    }
    return (
        <TelemetryLink href={`${t("profilePage.linkedinPrefix")}${username}`}>
            <img src={LinkedinIcon} alt={t("linkedin")} width="24px" />
        </TelemetryLink>
    );
};
