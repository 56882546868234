import { GetServerDataProps, GetServerDataReturn } from "gatsby";
import React from "react";
import UserProfile from "../../components/PageSwitch/pages/UserProfile/UserProfile.page";
import { getUserProfile, IUserProfileResponse } from "../../hooks/api/useUserProfile";

interface IServerData {
    readonly profile: IUserProfileResponse | null;
    readonly username: string;
}

const Page: React.FC<React.PropsWithChildren<{ readonly serverData: IServerData }>> = ({ serverData }) => {
    const { profile, username } = serverData;
    return <UserProfile profile={profile} username={username} />;
};

export default Page;

const getStringValue = (value: unknown) => {
    return typeof value === "string" ? value : "";
};

export const getServerData = async (context: GetServerDataProps): Promise<GetServerDataReturn<IServerData>> => {
    const username: string = getStringValue(context.params?.username ?? "");
    const data = await getUserProfile({}, username);

    return {
        status: 200,
        props: {
            username,
            profile: data,
        },
    };
};
