import * as React from "react";
import { useTranslation } from "react-i18next";
import TwitterIcon from "./twitter.svg";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";

interface IProps {
    readonly username?: string | null;
}

export const TwitterProperty: React.FC<React.PropsWithChildren<IProps>> = ({ username }) => {
    const [t] = useTranslation();
    if (username === null || undefined || username?.length == 0) {
        return null;
    }
    return (
        <TelemetryLink href={`${t("profilePage.twitterPrefix")}${username}`}>
            <img src={TwitterIcon} alt={t("twitter")} width="24px" />
        </TelemetryLink>
    );
};
