import styled from "styled-components";
import { Viewport } from "../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../theme/Palette";

export const Root = styled.div`
    display: flex;
    padding: 128px 0px;
    background-color: ${Palette.Neutral0};
`;

export const Container = styled.div`
    @media screen and (max-width: ${Viewport.Large}px) {
        max-width: 800px;
    }
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    border: 1px solid ${Palette.Neutral20};
    border-radius: 4px;
    padding: 32px;
    text-align: center;
    justify-content: center;
    justify-self: center;
    gap: 16px;
`;

export const Headline = styled.h1`
    font-size: 24px;
    font-weight: 700;
`;

export const InformationContainer = styled.div`
    display: flex;
    gap: 8px;
    justify-content: center;
`;

export const SocialsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;

    :empty {
        display: none;
    }
`;

export const PropertiesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    margin: 0 auto;

    :empty {
        display: none;
    }
`;

export const CountryContainer = styled.div`
    display: flex;
    justify-content: center;

    :empty {
        display: none;
    }
`;