import * as React from "react";
import { Text } from "@fluentui/react";

interface IProps {
    readonly level?: string | null;
}

export const LevelProperty: React.FC<React.PropsWithChildren<IProps>> = ({ level }) => {
    if (level === null || undefined || level?.length == 0) {
        return null;
    }
    return (
        <>
            <Text>{level}</Text>
            <Text>{" · "}</Text>
        </>
    );
};
