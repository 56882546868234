import styled from "styled-components";

export const DetailContainer = styled.div`
    display: grid;
    grid-template-columns: 24px auto;
    justify-items: start;
    grid-column-gap: 8px;
    align-items: center;
    line-height: 100%;
`;
